'use client';
import { removeCookies } from 'cookies-next';
import { useRouter } from 'next/navigation';
import { Auth } from 'aws-amplify';
import * as Sentry from '@sentry/browser';
import { useDispatch } from 'react-redux';

import { COGNITO_JWT } from 'src/constants/loggedIn';
import { DEMO_SIGN_IN_PAGE, SIGN_IN_PAGE } from 'src/constants/paths';
import {
  CAPITAL_IDENTIFICATION,
  COGNITO_USER_ID,
  EMAIL,
  USER_ID,
} from 'src/constants/registration';
import { isDemo } from 'src/_middleware';
import { useMixpanel } from './useMixpanel';
import { revertAll } from 'src/store/app/reducer';
import { userApi } from 'src/store/user/api';
import { http } from 'src/utils/axios';
import { findLocalItems } from 'src/utils/localStorageActions';

export const logOutFunction = () => {
  Sentry.setUser(null);
  localStorage.clear();
  const cookiesForRemove = [COGNITO_JWT, COGNITO_USER_ID, EMAIL, USER_ID, CAPITAL_IDENTIFICATION];
  cookiesForRemove.forEach((key) => removeCookies(key));
  Auth.signOut();
};

export const useLogout = () => {
  const { mixpanelLogout } = useMixpanel();
  const { push } = useRouter();
  const dispatch = useDispatch();

  const logout = () => {
    if (isDemo) {
      const token = findLocalItems();
      const authorization = token ? { Authorization: `Bearer ${token}` } : {};
      const headers = {
        'content-type': 'application/json',
        ...authorization,
      } as any;
      http({
        url: '/demo-users',
        method: 'PATCH',
        data: JSON.stringify({ occupiedBy: null }),
        headers,
      });
    }
    logOutFunction();
    mixpanelLogout();
    dispatch(revertAll());
    dispatch(userApi.util.resetApiState());
    push(isDemo ? DEMO_SIGN_IN_PAGE : SIGN_IN_PAGE);
  };
  return { logout };
};

export const useSoftRegisteredUserLogout = () => {
  const { mixpanelLogout } = useMixpanel();
  const logout = () => {
    logOutFunction();
    mixpanelLogout();
  };
  return { logout };
};
