import { FileField } from './common';

export interface UserContract {
  customerContractInstanceId: number;
  investmentOfferingId: number;
  contractType: ContractQuestionnaireType;
  updatedAt: string;
  legalOsQuestionnaireId: string;
  ownerId: 0;
  file: FileField;
}

export enum ContractQuestionnaireType {
  AQUATY_TERMS_AND_CONDITIONS = 'AQUATY_TERMS_AND_CONDITIONS',
  PRIVACY_POLICY = 'PRIVACY_POLICY',
  CONSUMER_CANCELLATION_POLICY = 'CONSUMER_CANCELLATION_POLICY',
  GENERAL_RISK_FACTORS = 'GENERAL_RISK_FACTORS',
  CONSUMER_INFORMATION = 'CONSUMER_INFORMATION',
  KEY_INFORMATION_DOCUMENT = 'KEY_INFORMATION_DOCUMENT',
  PARTICIPATION_RIGHT = 'PARTICIPATION_RIGHT',
  CONCEDUS_TERMS_AND_CONDITIONS = 'CONCEDUS_TERMS_AND_CONDITIONS',
  CONCEDUS_PRIVACY_INFORMATION = 'CONCEDUS_PRIVACY_INFORMATION',
  CONCEDUS_BROKERAGE_CONDITIONS = 'CONCEDUS_BROKERAGE_CONDITIONS',
  CONCEDUS_CUSTOMER_INFORMATION = 'CONCEDUS_CUSTOMER_INFORMATION',
  SPV_ISSUANCE_SHAREHOLDERS_RESOLUTION = 'SPV_ISSUANCE_SHAREHOLDERS_RESOLUTION',
  COSTS_INFORMATION = 'COSTS_INFORMATION',
  SIGNING_SUMMARY = 'SIGNING_SUMMARY',
  TANGANY_TERMS_AND_CONDITIONS = 'TANGANY_TERMS_AND_CONDITIONS',
  INVESTMENT_MANAGER_TERMS_AND_CONDITIONS = 'INVESTMENT_MANAGER_TERMS_AND_CONDITIONS',
  ISSUER_TERMS_AND_CONDITIONS = 'ISSUER_TERMS_AND_CONDITIONS',
  ARTICLES_OF_ASSOCIATION = 'ARTICLES_OF_ASSOCIATION', // Deprecated
  EQUITY_OFFERING_SHAREHOLDER_RESOLUTION = 'EQUITY_OFFERING_SHAREHOLDER_RESOLUTION', // Deprecated
  TERM_SHEET = 'TERM_SHEET', // Deprecated
  VENTURE_INVESTOR_RIGHTS_AND_OBLIGATIONS_BASE = 'VENTURE_INVESTOR_RIGHTS_AND_OBLIGATIONS_BASE', // Deprecated
  VENTURE_PARTNER_BASE = 'VENTURE_PARTNER_BASE', // Deprecated
  VENTURE_PARTNER_TRANSACTION = 'VENTURE_PARTNER_TRANSACTION', // Deprecated
}
