import { gql, useLazyQuery } from '@apollo/client';
import { FileField } from 'src/interfaces/common';
import { fileFields } from '../common.strings';
// TODO deprecated
const downloadContractDocumentQueryString = gql`
  query downloadContractDocument($contractInstanceIds: [Long]!) {
    downloadContractDocument(contractInstanceIds: $contractInstanceIds) {
      ${fileFields}
    }
  }
`;

export function downloadDocument(file: FileField) {
  const url = file.downloadUrl as string;
  const a = document.createElement('a');
  a.href = url;
  a.download = url.split('/').pop() as string;
  document.body.appendChild(a);
  a.target = '_blank';
  a.click();
  document.body.removeChild(a);
}

export const useDownloadContract = (downloadOnCompleted: boolean = true) => {
  const [downloadContract, { data, loading }] = useLazyQuery<
    { downloadContractDocument: FileField },
    { contractInstanceIds: number[] }
  >(downloadContractDocumentQueryString, {
    onCompleted: ({ downloadContractDocument }) => {
      if (downloadOnCompleted) {
        downloadDocument(downloadContractDocument);
      }
    },
  });
  return {
    loading,
    downloadContract,
    downloadContractDocument: data?.downloadContractDocument,
  };
};
