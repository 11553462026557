import { SVGProps, memo } from 'react';

const SvgTimeClockCircle48Px = (props: SVGProps<SVGSVGElement>) => (
  <svg width={48} height={48} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#time-clock-circle-48px_svg__a)">
      <path
        d="M24 4a20 20 0 1 0 0 40 20 20 0 0 0 0-40Zm8.667 28.7a1.668 1.668 0 0 1-2.367.1l-8.333-7.567A1.666 1.666 0 0 1 21.5 24v-9.167a1.667 1.667 0 0 1 3.333 0v8.434l7.717 7.083a1.666 1.666 0 0 1 .117 2.35Z"
        fill="#EBAA05"
      />
    </g>
    <defs>
      <clipPath id="time-clock-circle-48px_svg__a">
        <path fill="#fff" transform="translate(4 4)" d="M0 0h40v40H0z" />
      </clipPath>
    </defs>
  </svg>
);

const Memo = memo(SvgTimeClockCircle48Px);
export default Memo;
