import { ContractQuestionnaireType } from 'src/interfaces/user-contract';

export const userContractsLabels = {
  [ContractQuestionnaireType.AQUATY_TERMS_AND_CONDITIONS]:
    'aquaty-generic.cta.terms-and-conditions',
  [ContractQuestionnaireType.PRIVACY_POLICY]: 'aquaty-generic.cta.data-privacy-policy',
  [ContractQuestionnaireType.CONSUMER_CANCELLATION_POLICY]:
    'aquaty-app.contracts.' +
    ContractQuestionnaireType.CONSUMER_CANCELLATION_POLICY.toLowerCase().split('_').join('-') +
    '.title',
  [ContractQuestionnaireType.GENERAL_RISK_FACTORS]:
    'aquaty-app.contracts.' +
    ContractQuestionnaireType.GENERAL_RISK_FACTORS.toLowerCase().split('_').join('-') +
    '.title',
  [ContractQuestionnaireType.CONSUMER_INFORMATION]:
    'aquaty-app.contracts.' +
    ContractQuestionnaireType.CONSUMER_INFORMATION.toLowerCase().split('_').join('-') +
    '.title',
  [ContractQuestionnaireType.KEY_INFORMATION_DOCUMENT]:
    'aquaty-app.contracts.' +
    ContractQuestionnaireType.KEY_INFORMATION_DOCUMENT.toLowerCase().split('_').join('-') +
    '.title',
  [ContractQuestionnaireType.PARTICIPATION_RIGHT]:
    'aquaty-app.contracts.' +
    ContractQuestionnaireType.PARTICIPATION_RIGHT.toLowerCase().split('_').join('-') +
    '.title',
  [ContractQuestionnaireType.CONCEDUS_TERMS_AND_CONDITIONS]:
    'aquaty-app.contracts.' +
    ContractQuestionnaireType.CONCEDUS_TERMS_AND_CONDITIONS.toLowerCase().split('_').join('-') +
    '.title',
  [ContractQuestionnaireType.CONCEDUS_PRIVACY_INFORMATION]:
    'aquaty-app.contracts.' +
    ContractQuestionnaireType.CONCEDUS_PRIVACY_INFORMATION.toLowerCase().split('_').join('-') +
    '.title',
  [ContractQuestionnaireType.CONCEDUS_BROKERAGE_CONDITIONS]:
    'aquaty-app.contracts.' +
    ContractQuestionnaireType.CONCEDUS_BROKERAGE_CONDITIONS.toLowerCase().split('_').join('-') +
    '.title',
  [ContractQuestionnaireType.CONCEDUS_CUSTOMER_INFORMATION]:
    'aquaty-app.contracts.' +
    ContractQuestionnaireType.CONCEDUS_CUSTOMER_INFORMATION.toLowerCase().split('_').join('-') +
    '.title',
  [ContractQuestionnaireType.SPV_ISSUANCE_SHAREHOLDERS_RESOLUTION]:
    'aquaty-app.contracts.' +
    ContractQuestionnaireType.SPV_ISSUANCE_SHAREHOLDERS_RESOLUTION.toLowerCase()
      .split('_')
      .join('-') +
    '.title',
  [ContractQuestionnaireType.COSTS_INFORMATION]:
    'aquaty-app.contracts.' +
    ContractQuestionnaireType.COSTS_INFORMATION.toLowerCase().split('_').join('-') +
    '.title',
  [ContractQuestionnaireType.SIGNING_SUMMARY]:
    'aquaty-app.contracts.' +
    ContractQuestionnaireType.SIGNING_SUMMARY.toLowerCase().split('_').join('-') +
    '.title',
  [ContractQuestionnaireType.TANGANY_TERMS_AND_CONDITIONS]:
    'aquaty-app.contracts.' +
    ContractQuestionnaireType.TANGANY_TERMS_AND_CONDITIONS.toLowerCase().split('_').join('-') +
    '.title',
  [ContractQuestionnaireType.ARTICLES_OF_ASSOCIATION]:
    'aquaty-app.contracts.' +
    ContractQuestionnaireType.ARTICLES_OF_ASSOCIATION.toLowerCase().split('_').join('-') +
    '.title',
  [ContractQuestionnaireType.EQUITY_OFFERING_SHAREHOLDER_RESOLUTION]:
    'aquaty-app.contracts.' +
    ContractQuestionnaireType.EQUITY_OFFERING_SHAREHOLDER_RESOLUTION.toLowerCase().replace(
      '_',
      '-',
    ) +
    '.title',
  [ContractQuestionnaireType.TERM_SHEET]:
    'aquaty-app.contracts.' +
    ContractQuestionnaireType.TERM_SHEET.toLowerCase().split('_').join('-') +
    '.title',
  [ContractQuestionnaireType.VENTURE_INVESTOR_RIGHTS_AND_OBLIGATIONS_BASE]:
    'aquaty-app.contracts.' +
    ContractQuestionnaireType.VENTURE_INVESTOR_RIGHTS_AND_OBLIGATIONS_BASE.toLowerCase().replace(
      '_',
      '-',
    ) +
    '.title',
  [ContractQuestionnaireType.VENTURE_PARTNER_BASE]:
    'aquaty-app.contracts.' +
    ContractQuestionnaireType.VENTURE_PARTNER_BASE.toLowerCase().split('_').join('-') +
    '.title',
  [ContractQuestionnaireType.VENTURE_PARTNER_TRANSACTION]:
    'aquaty-app.contracts.' +
    ContractQuestionnaireType.VENTURE_PARTNER_TRANSACTION.toLowerCase().split('_').join('-') +
    '.title',
  [ContractQuestionnaireType.INVESTMENT_MANAGER_TERMS_AND_CONDITIONS]:
    'aquaty-app.contracts.' +
    ContractQuestionnaireType.INVESTMENT_MANAGER_TERMS_AND_CONDITIONS.toLowerCase()
      .split('_')
      .join('-') +
    '.title',
  [ContractQuestionnaireType.ISSUER_TERMS_AND_CONDITIONS]:
    'aquaty-app.contracts.' +
    ContractQuestionnaireType.ISSUER_TERMS_AND_CONDITIONS.toLowerCase().split('_').join('-') +
    '.title',
};
