import { useEffect, useState } from 'react';
import App, { AppContext } from 'next/app';
import type { AppProps } from 'next/app';
import { Amplify } from 'aws-amplify';
import { Provider } from 'react-redux';
import UAParser from 'ua-parser-js';
import { appWithTranslation } from 'next-i18next';

import { GlobalStyles } from 'src/shared/global-styles';
import { AppLayout } from 'src/server-shared/Layout';
import { AqNotification } from 'src/shared/AqNotification';
import { ApolloProvider } from 'src/providers/apollo-provider';
import { ThemeProvider } from 'src/providers/theme-provider';
import { RibbonProvider } from 'src/providers/ribbon-provider';
import { AppLoading } from 'src/server-shared/loader/app-loading';
import { NotSupportedView } from 'src/shared/not-supported-view';
import { MixpanelProvider } from 'src/providers/mixpanel-provider';
import SentryProvider from '../providers/sentry-provider';
import ConfirmationProvider from '../providers/confirmation-provider';

import store from 'src/store';

import '../styles/reset.css';
import '../styles/scss/index.scss';
import '../styles/contract-preview.css';
import '../styles/text-editor.css';
import 'react-image-crop/dist/ReactCrop.css';

import { ErrorBoundaryResettable } from 'src/ui/error-page/error-boundary';
import { ErrorCatcher } from 'src/ui/error-page/error-boundary/error-catcher';
import { CheckProjectVersion } from 'src/server-shared/Layout/check-project-version';
import { HubspotChat } from 'src/server-shared/Layout/hubspot-chat';
import { MobileSupportAlert } from 'src/providers/mobile-support';

export const AmplifyConfig = {
  Auth: {
    region: process.env.AWS_DEFAULT_REGION,
    userPoolId: process.env.AWS_COGNITO_USER_POOL_ID,
    identityPoolId: process.env.AWS_IDENTITY_POOL_ID,
    userPoolWebClientId: process.env.COGNITO_CLIENT_ID,
    oauth: {
      domain: process.env.COGNITO_DOMAIN,
      scope: ['email', 'openid', 'profile'],
      redirectSignIn: process.env.NEXTAUTH_URL + '/auth/login',
      redirectSignOut: process.env.NEXTAUTH_URL + '/auth/logout',
      responseType: 'code',
    },
  },
  Storage: {
    bucket: process.env.AWS_BUCKET_NAME,
    region: process.env.AWS_DEFAULT_REGION,
    identityPoolId: process.env.AWS_IDENTITY_POOL_ID,
  },
};
Amplify.configure({
  ...AmplifyConfig,
  Analytics: {
    disabled: true,
  },
});

if (process.browser) {
  window.addEventListener('load', function () {
    (window as any).allAssetsLoaded = true;
    console.log('All assets loaded');
  });
}

function AquatyWeb({ Component, pageProps }: AppProps<{ browser?: UAParser.IBrowser }>) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      if ((window as any).allAssetsLoaded) {
        setLoading(false);
        clearInterval(interval);
        console.log('All assets loaded - interval must be destroyed');
      }
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, []);

  // handling exception for Safari iOS 15
  if (
    pageProps.browser &&
    pageProps.browser.name === 'Safari' &&
    pageProps.browser?.version &&
    parseInt(pageProps.browser?.version) <= 15
  ) {
    return (
      <>
        <NotSupportedView />
        <GlobalStyles />
      </>
    );
  }

  if (loading) {
    return <AppLoading />;
  }

  return (
    <>
      <ThemeProvider>
        <ApolloProvider>
          <Provider store={store}>
            <MixpanelProvider>
              <SentryProvider>
                <ErrorBoundaryResettable>
                  <ConfirmationProvider>
                    <RibbonProvider>
                      <AppLayout>
                        <MobileSupportAlert />
                        <ErrorCatcher />
                        <Component {...pageProps} />
                        <AqNotification />
                        <CheckProjectVersion />
                      </AppLayout>
                    </RibbonProvider>
                  </ConfirmationProvider>
                </ErrorBoundaryResettable>
              </SentryProvider>
            </MixpanelProvider>
            <HubspotChat />
          </Provider>
        </ApolloProvider>
      </ThemeProvider>
      <GlobalStyles />
    </>
  );
}

export default appWithTranslation(AquatyWeb);

AquatyWeb.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext);

  const UserAgentInstance = new UAParser(appContext.ctx.req?.headers['user-agent']);
  return {
    ...appProps,
    pageProps: {
      browser: UserAgentInstance.getBrowser ? UserAgentInstance.getBrowser() : undefined,
    },
  };
};
