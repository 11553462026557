'use client';

import { FC, MouseEvent, useEffect, useLayoutEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

import { ServerSharedLoader } from 'src/server-shared/loader';
import { ModalHeader } from './components/header';
import { ModalFooter } from './components/footer';
import {
  SharedModalProps,
  GeneralModalProps,
  ModalFooterProps,
} from 'src/server-shared/modals/modal/types';
import { classNames } from 'src/utils/classNames';
import classes from './index.module.scss';

export type { GeneralModalProps, SharedModalProps, ModalFooterProps };

export const ServerSharedModal: FC<SharedModalProps> = ({
  closeEvent,
  extraClickOutside,
  closeByClickOutside = true,
  children,
  show,
  size = 'small',
  header,
  footer,
  loading,
  bodyInlineStyles,
  isVisible = false,
}) => {
  const modal = useRef<HTMLDivElement>(null);
  const [headerHeight, setHeaderHeight] = useState(0);

  useEffect(() => {
    let scrollY = 0;
    if (show) {
      scrollY = window.scrollY;

      document.body.style.position = 'fixed';
      document.body.style.top = `-${scrollY}px`;
      document.body.style.width = '100%';
    }

    return () => {
      document.body.style.position = 'unset';
      window.scrollTo(0, scrollY);
      document.body.style.width = '';
    };
  }, [show]);

  const handleClickOutside = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    if (closeByClickOutside && modal.current) {
      if (event.target instanceof HTMLElement && !modal.current.contains(event.target)) {
        if (extraClickOutside) {
          extraClickOutside();
        } else {
          closeEvent();
        }
      }
    }
  };

  useLayoutEffect(() => {
    if (show && header) {
      const modalHeader: HTMLDivElement | undefined | null = modal.current?.querySelector(
        'div[role="modal-header"]',
      );
      setHeaderHeight(modalHeader?.offsetHeight || 0); // for safari browser
    }
  }, [header, show]);

  if (!show) {
    return null;
  }

  const headerProps = typeof header === 'boolean' ? {} : header;
  const footerProps = typeof footer === 'boolean' ? {} : footer;

  return ReactDOM.createPortal(
    <div className={classes['modal-backdrop']} id="portal-modal" onClick={handleClickOutside}>
      <div
        className={classNames(classes.modal, {
          [classes[`modal--${size}`]]: true,
          [classes['modal--visible']]: isVisible,
        })}
        ref={modal}
      >
        {!!header && <ModalHeader {...headerProps} closeEvent={closeEvent} />}
        <div
          className={classes['modal-body']}
          style={{
            maxHeight: `calc(100vh - (24px * 2) - 72px - ${headerHeight}px)`,
            ...bodyInlineStyles,
          }}
        >
          {loading ? (
            <div className={classes['body-loader-container']}>
              <ServerSharedLoader inModal />
            </div>
          ) : (
            children
          )}
        </div>
        {!!footer && <ModalFooter {...footerProps} closeEvent={closeEvent} />}
      </div>
    </div>,
    document.body,
  );
};
