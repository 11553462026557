import { useEffect, useState } from 'react';
import { useWindowSize } from 'src/hooks/useWindowSize';
import { MobileSupportModal } from 'src/server-shared/modals/feedback/mobile-support';
import { breakpoints } from 'src/styles/breakpoints';

export const MobileSupportAlert = () => {
  const { width } = useWindowSize();
  const [showSupportModal, setShowSupportModal] = useState(false);

  useEffect(() => {
    if (width < breakpoints.lg) {
      setShowSupportModal(true);
    }
  }, [width]);
  return (
    <>
      {showSupportModal && (
        <MobileSupportModal show closeEvent={() => setShowSupportModal(false)} />
      )}
    </>
  );
};
