import { FC, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import mixpanel from 'mixpanel-browser';

import * as paths from 'src/constants/paths';
import { useMixpanel, mixpanelEvents } from '../hooks/useMixpanel';
import { useSelector } from 'react-redux';
import { getCurrentRole } from 'src/store/app/reducer';
import { Roles } from '../interfaces/user';

mixpanel.init(process.env.MIXPANEL_KEY ?? '', {
  debug: process.env.RUNTIME_ENVIRONMENT === 'staging',
  opt_out_tracking_by_default: process.env.NEXTAUTH_URL?.includes('localhost'),
});

const pageNames: Record<string, any> = {
  [paths.SIGN_IN_PAGE]: { page: 'Sign In' },
  [paths.SIGN_UP_PAGE]: { page: 'Sign Up' },
  [paths.REGISTRATION_PAGE]: { page: 'Registration' },
  [paths.HOME_PAGE]: { page: 'Home' },
  [paths.PROFILE_COMPLETION_PAGE]: { page: 'Complete Registration' },
  [paths.MANAGER_TERM_SHEET_SETUP_PAGE]: { page: 'Term Sheet Setup' },
  [paths.MANAGER_VENTURE_PITCH_SETUP_PAGE]: { page: 'Venture Pitch Setup' },
  [paths.MANAGER_CAP_TABLE_SETUP_PAGE]: { page: 'Cap Table Setup' },
  [paths.MANAGER_INVESTMENT_OFFERING_SETUP_PAGE]: { page: 'Investment Offering Setup' },
  [paths.MANAGER_DATA_ROOM_SETUP_PAGE]: { page: 'Data Room Setup' },
  [paths.MANAGER_VENTURE_LEGAL_DOCUMENTS_PAGE]: { page: 'Provide Documents' },
  [paths.MENU_PORTFOLIO_TAX_INFORMATION]: { page: 'Menu Portfolio Tax Information' },
  [paths.MENU_NOTIFICATION_PAGE]: { page: 'Menu Notifications' },
  [paths.PORTFOLIO_VENTURES_UPDATES_PAGE]: { page: 'Portfolio Updates' },
  [paths.PORTFOLIO_UPDATES_PAGE]: { page: 'Portfolio Updates' },
  [paths.PORTFOLIO_DETAIL_PAGE]: { page: 'Portfolio Details' },
  [paths.OFFLINE_PORTFOLIO_DETAIL_PAGE]: { page: 'Portfolio Details' },
  [paths.OFFLINE_PORTFOLIO_UPDATES_PAGE]: { page: 'Offline Portfolio Updates' },
  [paths.ISSUER_SETUP_PAGE]: { page: 'Create Issuer' },
  [paths.TERM_SHEET_REVIEW_PAGE]: { page: 'Term Sheet Review' },
  [paths.DEAL_TEAM_SETUP_PAGE]: { page: 'Deal Team Setup' },
  [paths.CAPITAL_TRANSFER_PAGE]: { page: 'Capital Transfer' },
  [paths.PAYMENT_CONFIRMATION_PAGE]: { page: 'Investment Commitment Payment' },
  [paths.RELEASE_EQUITY_OFFERING_PAGE]: { page: 'Release Equity Offering' },
  [paths.RELEASE_TO_ALL_INVESTORS_PAGE]: { page: 'Term Sheet Review' },
  [paths.ROLE_ASSIGNMENT_PAGE]: { page: 'Role Assignment' },
  [paths.CLOSE_EQUITY_OFFERING_PAGE]: { page: 'Close Equity Offering' },
  [paths.CONFIRM_FINDING_AMOUNT_PAGE]: { page: 'Confirm Minimum Funding Amount commited' },
  [paths.DEAL_ROOM_PAGE]: { page: 'Deal Room' },
  [paths.ASSET_INVESTMENT_CLOSING_PAGE]: { page: 'Asset Investment Closing' },
  [paths.REGULATORY_APPROVAL_PAGE]: { page: 'Regulatory Approval' },
  [paths.INVESTMENT_MEMORANDUM_SETUP_PAGE]: { page: 'Investment Memorandum Setup' },
  [paths.DATA_ROOM_REVIEW_PAGE]: { page: 'Due Diligence Review' },
  [paths.SIGN_INVESTMENT_PAGE]: { page: 'Sign Your Investment' },
  [paths.SPV_ISSUANCE_AGREEMENT_SIGNING]: { page: 'SPV Issuance Agreement Signing' },
  [paths.MENU_PAGE]: { page: 'Menu Page' },
  [paths.MENU_WALLET_SETUP_PAGE]: { page: 'Menu Wallet Setup' },
  ['/']: { page: 'Index Page' },
};

export const MixpanelProvider: FC = (props) => {
  const router = useRouter();
  const { track } = useMixpanel();
  const initialLoad = useRef(true);
  const role = useSelector(getCurrentRole);

  useEffect(() => {
    const handleRouteChange = () => {
      if (
        router.route === paths.HOME_PAGE &&
        !(role && [Roles.INVESTOR, Roles.VENTURE_FOUNDER].includes(role))
      ) {
        return;
      }

      const pageData = pageNames[router.route];

      if (pageData) {
        track(mixpanelEvents.userViewPage, pageData);
      }
    };

    if (initialLoad.current) {
      handleRouteChange();
      if (!(router.route === paths.HOME_PAGE && !role)) {
        initialLoad.current = false;
      }
    }

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router, role, track]);

  return <>{props.children}</>;
};
