import React, { FC } from 'react';
import { FileField } from 'src/interfaces/common';
import classes from './index.module.scss';

interface PDFViewerProps {
  file?: FileField | null;
}

export const ServerSharedPDFViewer: FC<PDFViewerProps> = ({ file }) => {
  if (!file) {
    return null;
  }

  return (
    <div>
      <iframe
        className={classes['styled-iframe']}
        title="pdf document"
        id="print-file"
        src={file.publicFileUrl}
      />
    </div>
  );
};
