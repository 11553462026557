import { SVGProps, memo } from 'react';

const SvgBinCircle48Px = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={48}
    height={48}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.33008 24.0002C5.33008 13.4128 13.9128 4.82999 24.5002 4.82999C35.0875 4.82999 43.6702 13.4128 43.6702 24.0002C43.6702 34.5875 35.0874 43.17 24.5002 43.17C13.9128 43.17 5.33008 34.5875 5.33008 24.0002ZM12.876 17.1006C12.876 16.4103 13.4357 15.8506 14.126 15.8506H34.8742C35.5647 15.8506 36.1242 16.4103 36.1242 17.1006C36.1242 17.791 35.5647 18.3506 34.8742 18.3506H33.2495L32.1619 33.2657C32.1619 34.1077 31.32 34.7813 30.478 34.7813H18.5226C17.6807 34.7813 16.8388 34.1077 16.8388 33.2657L15.7512 18.3506H14.126C13.4357 18.3506 12.876 17.791 12.876 17.1006ZM22.5932 20.9673C22.5932 20.277 22.0335 19.7173 21.3432 19.7173C20.6529 19.7173 20.0932 20.277 20.0932 20.9673V29.8982C20.0932 30.5885 20.6529 31.1482 21.3432 31.1482C22.0335 31.1482 22.5932 30.5885 22.5932 29.8982V20.9673ZM27.4887 19.7173C28.179 19.7173 28.7387 20.277 28.7387 20.9673V29.8982C28.7387 30.5885 28.179 31.1482 27.4887 31.1482C26.7984 31.1482 26.2387 30.5885 26.2387 29.8982V20.9673C26.2387 20.277 26.7984 19.7173 27.4887 19.7173Z"
      fill="#fff"
    />
  </svg>
);
const Memo = memo(SvgBinCircle48Px);
export default Memo;
