// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const removeProperty = (propKey: string, { [propKey]: propValue, ...rest }) => rest;

export const removeProperties = (object: any, ...keys: string[]): any =>
  keys.length ? removeProperties(removeProperty(keys.pop() || '', object), ...keys) : object;

export const objectHasProperty = (object: Record<string, any>, p: string) => {
  return Object.prototype.hasOwnProperty.call(object, p);
};

export const isPropertyIsTruthy = (object: Record<string, any>, p: string) => {
  return (
    objectHasProperty(object, p) &&
    object[p] !== undefined &&
    object[p] !== null &&
    object[p] !== ''
  );
};

export function removeNestedNullUndefined(obj: Record<string, any>) {
  const copy = { ...obj };
  for (const key in copy) {
    if (copy[key] === null || copy[key] === undefined) {
      delete copy[key];
    } else if (typeof copy[key] === 'object') {
      removeNestedNullUndefined(obj[key]);
    }
  }
  return copy;
}
