import { i18n } from '../../next-i18next.config';

export const HOME_PAGE = '/home';
export const MENU_PAGE = '/menu';
export const MENU_ADD_HOLDING_PAGE = '/menu/add-holding';
export const MENU_NOTIFICATION_PAGE = '/menu/notifications';
export const ISSUERS_PAGE = '/issuers';
export const ISSUER_DETAILS_PAGE = '/issuers/[issuerId]';
export const ISSUER_SPV_VERIFICATION_PAGE =
  '/issuers/[issuerId]/securitization-vehicles-verification';
export const PORTFOLIO_PAGE = '/portfolio';
export const PORTFOLIO_DETAIL_PAGE = '/portfolio/[assetId]';
export const PORTFOLIO_INVESTMENT_OFFERING_PAGE = '/portfolio/[assetId]/[offeringId]';
export const PORTFOLIO_UPDATES_PAGE = '/portfolio/[assetId]/updates';
export const OFFERINGS_PAGE = '/investment-offerings';
export const DEAL_ROOM_PAGE = '/investment-offerings/[offeringId]';
export const ROLE_ASSIGNMENT_PAGE = '/investment-offerings/[offeringId]/role-assignment';
export const CLOSE_EQUITY_OFFERING_PAGE =
  '/investment-offerings/[offeringId]/close-equity-offering';
export const SIGN_INVESTMENT_PAGE = '/investment-offerings/[offeringId]/sign-investment';
export const DATA_ROOM_REVIEW_PAGE = '/investment-offerings/[offeringId]/data-room-review';
export const CAPITAL_TRANSFER_PAGE = '/investment-offerings/[offeringId]/capital-transfer';
export const RELEASE_EQUITY_OFFERING_PAGE =
  '/investment-offerings/[offeringId]/release-equity-offering';
export const PAYMENT_CONFIRMATION_PAGE = '/investment-offerings/[offeringId]/payment-confirmation';
export const ASSET_INVESTMENT_CLOSING_PAGE =
  '/investment-offerings/[offeringId]/asset-investment-closing';
export const CONFIRM_FINDING_AMOUNT_PAGE =
  '/investment-offerings/[offeringId]/confirm-minimum-funding-amount-committed';
export const USERS_PAGE = '/users';
export const DEAL_TEAM_SETUP_PAGE = '/investment-offerings/[offeringId]/deal-team-setup';
export const USER_DETAILS_PAGE = '/users/[userId]';
export const ADMIN_BENEFICIAL_OWNER_VERIFICATION_PAGE =
  '/users/[userId]/beneficial-owner-self-selection-verification';
export const PORTFOLIO_VENTURES_UPDATES_PAGE = '/portfolio/updates';
export const SPV_ISSUANCE_AGREEMENT_SIGNING =
  '/investment-offerings/[offeringId]/spv-issuance-agreement-signing';
export const TERM_SHEET_REVIEW_PAGE = '/investment-offerings/[offeringId]/term-sheet-review';
export const RELEASE_TO_ALL_INVESTORS_PAGE =
  '/investment-offerings/[offeringId]/release-to-all-aquaty-investors';
export const OFFLINE_PORTFOLIO_PAGE = '/offline-portfolio';
export const OFFLINE_PORTFOLIO_DETAIL_PAGE = '/offline-portfolio/[ventureId]';
export const OFFLINE_PORTFOLIO_UPDATES_PAGE = '/offline-portfolio/[ventureId]/updates';
export const REGULATORY_APPROVAL_PAGE = '/investment-offerings/[offeringId]/regulatory-approval';
export const MENU_PORTFOLIO_TAX_INFORMATION = '/menu/portfolio-tax-information';
export const PORTFOLIO_CAP_TABLE_SETUP = '/offline-portfolio/[ventureId]/cap-table-setup';
export const INVESTMENT_MEMORANDUM_SETUP_PAGE =
  '/investment-offerings/[offeringId]/investment-memorandum-setup';
export const INVESTMENT_OFFERING_SETUP_PAGE =
  '/venture/[ventureId]/investment-offerings/[offeringId]/investment-offering-setup';
export const MANAGER_INVESTMENT_OFFERING_SETUP_PAGE =
  '/investment-offerings/[offeringId]/investment-offering-setup';
export const MENU_PLATFORM_CONFIGURATION_PAGE = '/menu/platform-configuration';
export const MANAGER_CAP_TABLE_SETUP_PAGE = '/investment-offerings/[offeringId]/cap-table-setup';
export const MANAGER_VENTURE_PITCH_SETUP_PAGE =
  '/investment-offerings/[offeringId]/venture-pitch-setup';
export const MANAGER_DATA_ROOM_SETUP_PAGE = '/investment-offerings/[offeringId]/data-room-setup';
export const MANAGER_TERM_SHEET_SETUP_PAGE = '/investment-offerings/[offeringId]/term-sheet-setup';
export const MANAGER_VENTURE_LEGAL_DOCUMENTS_PAGE =
  '/investment-offerings/[offeringId]/legal-documents';
export const INVESTOR_CRM_PAGE = '/investor-crm';
export const ISSUER_SETUP_PAGE = '/investment-offerings/issuer-setup';
export const MENU_WALLET_SETUP_PAGE = '/menu/wallet-setup';
export const OFFERINGS_ARCHIVE_PAGE = '/investment-offerings/archive';
export const OFFERINGS_COMPLETED_PAGE = '/investment-offerings/completed';

export const LINKEDIN_LOGIN_PAGE = '/auth/login';
export const RESET_PASSWORD_PAGE = '/reset-password';
export const SIGN_IN_PAGE = '/auth/sign-in';
export const DEMO_SIGN_IN_PAGE = '/auth/demo-sign-in';
export const SIGN_UP_PAGE = '/auth/sign-up';
export const REGISTRATION_PAGE = '/registration';
export const LETS_IN_TOUCH_PAGE = '/auth/sign-up/lets-in-touch';
export const PROFILE_COMPLETION_PAGE = '/profile-completion';
export const IDENTIFICATION_PAGE = '/profile-completion/identification';
export const INVESTMENT_EXPERIENCE_PAGE = '/profile-completion/investment-experience';
export const BENEFICIAL_OWNER_IDENTIFICATION_PAGE = '/beneficial-owner-identification';
export const CONGRATULATIONS_PAGE = '/congratulations';
export const IMPERSONATOR_PAGE = '/auth/impersonator';
export const ID_NOW_VERIFICATION_PAGE = '/profile-completion/id-now-verification';
export const SUCCESS_IDENTIFICATION_PAGE = '/profile-completion/success-identification';
export const FAIL_IDENTIFICATION_PAGE = '/profile-completion/fail-identification';

export const NOT_AUTHORIZED_PAGES = [
  LINKEDIN_LOGIN_PAGE,
  RESET_PASSWORD_PAGE,
  SIGN_IN_PAGE,
  DEMO_SIGN_IN_PAGE,
  SIGN_UP_PAGE,
  REGISTRATION_PAGE,
  LETS_IN_TOUCH_PAGE,
  PROFILE_COMPLETION_PAGE,
  IDENTIFICATION_PAGE,
  INVESTMENT_EXPERIENCE_PAGE,
  BENEFICIAL_OWNER_IDENTIFICATION_PAGE,
  CONGRATULATIONS_PAGE,
  IMPERSONATOR_PAGE,
  ID_NOW_VERIFICATION_PAGE,
  SUCCESS_IDENTIFICATION_PAGE,
  FAIL_IDENTIFICATION_PAGE,
]
  .map((path) =>
    i18n.locales.map((locale) => {
      if (locale === 'en') {
        return path;
      }
      return `/${locale}${path}`;
    }),
  )
  .flat();
