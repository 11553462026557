import { useCallback, useMemo } from 'react';

import { AQString } from 'src/shared/AQString';
import { UserContract } from 'src/interfaces/user-contract';
import { ContractPreviewProps } from 'src/server-shared/contract-preview';
import { ServerSharedModal, SharedModalProps } from 'src/server-shared/modals/modal';
import {
  downloadDocument,
  useDownloadContract,
} from 'src/queries/files-and-docs/download-contract-document.query';
import { ServerSharedButton } from 'src/server-shared/buttons/button';
import { spacing } from 'src/styles/spacing';
import { goToExternalLink } from 'src/utils/goToExternalLink';
import { ServerSharedPDFViewer } from 'src/server-shared/pdf-viewer';
import { ShareholderContract } from 'src/interfaces/contract';
import { IOAttachment } from 'src/interfaces/investment-offering';
import { FileField } from 'src/interfaces/common';
import { useLazyGetFileDownloadUrlQuery } from 'src/store/file/api';
import { generateQueryString } from 'src/utils/generate-query-string';

interface ContractModalProps {
  chosenContract: UserContract | ShareholderContract | IOAttachment | FileField;
  modalProps: SharedModalProps;
  contractComponentProps?: Partial<ContractPreviewProps>;
}

export const ContractModal = ({ chosenContract, modalProps }: ContractModalProps) => {
  const { show, closeEvent, ...rest } = modalProps;

  const [downloadAll, { isLoading }] = useLazyGetFileDownloadUrlQuery();
  const { downloadContractDocument } = useDownloadContract(false);

  const downloadContractHandler = useCallback(() => {
    if (downloadContractDocument) {
      downloadDocument(downloadContractDocument);
    }
    if ('file' in chosenContract && chosenContract.file.s3FileKey) {
      const fileKeys = chosenContract.file.s3FileKey;
      downloadAll(generateQueryString({ fileKeys }));
    }
  }, [chosenContract, downloadAll, downloadContractDocument]);

  const openContract = useCallback(() => {
    if (downloadContractDocument) {
      goToExternalLink(downloadContractDocument?.publicFileUrl || '');
    }
    if ('file' in chosenContract) {
      goToExternalLink(chosenContract.file.publicFileUrl || '');
    }
  }, [chosenContract, downloadContractDocument]);

  // useEffect(() => {
  //   downloadContract({ variables: { contractInstanceIds: [chosenContract] } });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const footer = useMemo(() => {
    if (downloadContractDocument || 'file' in chosenContract) {
      return {
        children: (
          <>
            <ServerSharedButton
              style={{
                marginRight: spacing.s,
              }}
              variant="secondary"
              onClick={openContract}
              label={<AQString componentId="aquaty-generic.cta.open-in-new-tab" />}
            />
            <ServerSharedButton
              onClick={downloadContractHandler}
              variant="inform"
              disabled={isLoading}
              label={<AQString componentId="aquaty-generic.cta.download-document" />}
            />
          </>
        ),
      };
    }
    return undefined;
  }, [chosenContract, downloadContractDocument, downloadContractHandler, isLoading, openContract]);

  return (
    <ServerSharedModal show={show} size="large" closeEvent={closeEvent} footer={footer} {...rest}>
      {'file' in chosenContract && <ServerSharedPDFViewer file={chosenContract.file} />}
    </ServerSharedModal>
  );
};
