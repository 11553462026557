import { FileField, Status } from 'src/interfaces/common';
import { Venture } from './venture';
import { UserHoldingRole, WalletDetails } from './profile-completion';
import { BeneficialOwner } from './beneficial-owner';
import { Company } from './company';
import { IssuerContactType } from './issuer';

export interface IIndustryForAPI {
  id: number;
}

export enum Roles {
  'VENTURE_FOUNDER' = 'VENTURE_FOUNDER',
  'INVESTOR' = 'INVESTOR',
  'VENTURE_PARTNER' = 'VENTURE_PARTNER',
  'EXPERT_PARTNER' = 'EXPERT_PARTNER',
  'VENTURE_SCOUT' = 'VENTURE_SCOUT',
  'AQUATY_STAFF' = 'AQUATY_STAFF',
  'SOFT_REGISTERED_VENTURE_INVESTOR' = 'SOFT_REGISTERED_VENTURE_INVESTOR',
  'INVESTMENT_MANAGER' = 'INVESTMENT_MANAGER',
}

export interface RoleAssignment {
  id: number;
  userId: number;
  role: Roles;
  verificationStatus: Status;
  verificationComment: string;
}

export enum UserRegistrationStatus {
  'COMPLETED' = 'COMPLETED',
  'ONBOARDING' = 'ONBOARDING',
  'WAITLIST' = 'WAITLIST',
}

export interface User {
  cognitoId?: string;
  userRoles: RoleAssignment[];
  firstName?: string;
  middleName?: string;
  email: string;
  userId: number;
  lastName?: string;
  fullName?: string;
  photo?: FileField;
  birthPlace?: string;
  nationality?: string;
  country?: string;
  street?: string;
  zip?: string;
  city?: string;
  birthDate?: string;
  phoneNumber?: string;
  phoneNumberCountryCode?: string;
  role?: Roles;
  createdAt: string;
  updatedAt: string;
}

export enum ETitle {
  'DR' = 'DR',
  'PROF' = 'PROF',
  'PROF_DR' = 'PROF_DR',
  'NONE' = 'NONE',
}

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  DIVERSE = 'DIVERSE',
}

export interface IUserInvestment {
  endDate: string;
  id?: number | string;
  investmentAmount: EVentureInvestmentAmount;
  investmentRoles: EInvestmentRole[];
  investmentType: EInvestmentType;
  startDate: string;
  venture: Venture;
}

export enum EVentureInvestmentAmount {
  'FROM_350_TO_1M' = 'FROM_350_TO_1M',
  'FROM_50_TO_350K' = 'FROM_50_TO_350K',
  'LESS_THAN_50K' = 'LESS_THAN_50K',
  'MORE_THAN_1M' = 'MORE_THAN_1M',
}

export enum EInvestmentType {
  'BUSINESS_ANGEL_CLUB' = 'BUSINESS_ANGEL_CLUB',
  'BY_MYSELF' = 'BY_MYSELF',
  'COMPANY_OR_CORPORATE_VC' = 'COMPANY_OR_CORPORATE_VC',
  'SYNDICATE' = 'SYNDICATE',
  'VC_FUND' = 'VC_FUND',
  'OTHER' = 'OTHER',
}

export enum EInvestmentRole {
  'DUE_DILIGENCE_PARTICIPANT' = 'DUE_DILIGENCE_PARTICIPANT',
  'INVESTMENT_COMMITTEE_LEAD' = 'INVESTMENT_COMMITTEE_LEAD',
  'INVESTMENT_COMMITTEE_PARTICIPANT' = 'INVESTMENT_COMMITTEE_PARTICIPANT',
  'INVESTMENT_REPORTING_PARTICIPANT' = 'INVESTMENT_REPORTING_PARTICIPANT',
  'OTHER' = 'OTHER',
  'STRATEGIC_ADVISOR' = 'STRATEGIC_ADVISOR',
}

// TODO
export interface IUserRoleAccreditationStatus {
  status: ERoleAccreditationStatus;
  refusalReason?: ERoleAccreditationRefusalReason;
  refusalNotes?: string;
}

export enum ERoleAccreditationStatus {
  'APPROVED' = 'APPROVED',
  'REFUSED' = 'REFUSED',
  'WAITING' = 'WAITING',
  'NOT_STARTED' = 'NOT_STARTED',
}

export enum ERoleAccreditationRefusalReason {
  'MORE_EXPERIENCE_NEEDED' = 'MORE_EXPERIENCE_NEEDED',
  'OTHER' = 'OTHER',
}

export interface UserIdentification {
  verificationStatus: Status;
  identId: string;
  identificationLink: string;
  updatedAt: string;
}

export interface UserAssignment {
  firstName?: string;
  fullName: string;
  id: number;
  image?: FileField;
  lastName?: string;
  role: Roles;
  email: string;
}

export enum UserTaxReportFileType {
  'TAX_REPORT' = 'TAX_REPORT',
  'UPLOADED_BY_MANAGER' = 'UPLOADED_BY_MANAGER',
  'UPLOADED_BY_USER' = 'UPLOADED_BY_USER',
}

export enum RevisableStatus {
  'ACCEPTED' = 'ACCEPTED',
  'NOT_STARTED' = 'NOT_STARTED',
  'REFUSED' = 'REFUSED',
  'TO_REWORK' = 'TO_REWORK',
  'WAITING' = 'WAITING',
}

export interface UserTaxReportFile {
  file: FileField;
  id: number;
  type: UserTaxReportFileType;
  uploadedAt: string;
}

export interface UserTaxReportComment {
  authorId: number;
  comment: string;
  id: number;
  createdAt: string;
}

export interface UserTaxReport {
  country: string;
  id: number;
  status: RevisableStatus;
  taxReportComments: UserTaxReportComment[];
  taxReportFiles: UserTaxReportFile[];
  updatedAt: string;
  userInvestmentAccountId: number;
  year: number;
}

export interface UserInvitation {
  email: string;
  invitedBy: number;
  equityOfferingId?: number;
  clubId?: number;
  createdAt: string;
  issuerMembershipType?: IssuerContactType;
  comment: string;
  grantDealRoomAccess: boolean;
  grantDataRoomAccess: boolean;
}

interface InvestmentAccountCompletion {
  beneficialOwnersIdentificationCompletion: { [key: string]: string | null };
  holdingName: string | null;
  walletVerificationDate: string;
}

export interface UserProfileCompletion {
  investmentAccountCompletions: InvestmentAccountCompletion[];
  kycCompletionDate: string | null;
  personalDataCompletionDate: string | null;
  roleAccreditationCompletionDate: string | null;
}

export interface IGetSingleUserParams {
  userId: number;
}

export interface ISingleElement<T> {
  data: T;
}

export enum UserInvestmentAccountType {
  'PRIVATE' = 'PRIVATE',
  'SPV' = 'SPV',
}

export enum UserInvestmentAccountStatus {
  'PENDING' = 'PENDING',
  'APPROVED' = 'APPROVED',
  'REJECTED' = 'REJECTED',
  'NOT_STARTED' = 'NOT_STARTED',
}

export interface UserInvestmentAccount {
  investmentAccountId: number;
  type: UserInvestmentAccountType;
  walletDetails: WalletDetails;
  holdingId: number; // TODO REMOVE
  updatedAt: string;
  legalEntity: {
    holdingParticipationRole: string;
    iban: string;
    bic: string;
    taxId: string;
    company: Company;
    verificationStatus: Status;
  };
}

export interface SaveUserInvestmentAccount {
  type: UserInvestmentAccountType;
  legalEntity: {
    holdingParticipationRole: UserHoldingRole;
    iban?: string;
    bic?: string;
    taxId?: string;
    company: {
      companyId: number;
    };
    beneficialOwners: BeneficialOwner;
  };
}

export interface DemoUser {
  userId: number;
  fullName: string;
  summary: string;
  photo: FileField;
  email: string;
  password: string;
  roomName: string;
  occupiedBy: string | null;
  fresh: boolean;
}
