/* eslint-disable indent */
import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery, http } from 'src/utils/axios';

import {
  InvestmentOffering,
  IOAttachment,
  IOCapitalisationDetails,
  IOInvestmentTerm,
  IOParticipant,
  IOInvestorDTO,
  IOSigning,
  IOInvestor,
  InvestmentOfferingForAPI,
} from 'src/interfaces/investment-offering';
import { addNotification } from '../app/reducer';

export const investmentOfferingApi = createApi({
  reducerPath: 'investmentOfferingApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    'Offering',
    'Participant',
    'Investment-Term',
    'Attachment',
    'Capitalisation-Detail',
    'Signing',
    'Investor',
  ],
  endpoints: (builder) => ({
    getInvestmentOfferings: builder.query<InvestmentOffering[], string>({
      query: (query) => ({
        url: `/investment-offerings?${query}`,
      }),
      keepUnusedDataFor: 0,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ investmentOfferingId }) => ({
                type: 'Offering' as const,
                id: investmentOfferingId,
              })),
              { type: 'Offering', id: 'LIST' },
            ]
          : [{ type: 'Offering', id: 'LIST' }],
    }),

    getInvestmentOfferingById: builder.query<InvestmentOffering, number | string>({
      query: (investmentOfferingId) => ({
        url: `/investment-offerings/${investmentOfferingId}`,
      }),
      keepUnusedDataFor: 0,
      providesTags: (result) =>
        result
          ? [
              {
                type: 'Offering' as const,
                id: result.investmentOfferingId,
              },
            ]
          : [],
    }),

    saveInvestmentOffering: builder.mutation<InvestmentOffering, Partial<InvestmentOfferingForAPI>>(
      {
        query: (data) => ({
          url: '/investment-offerings',
          method: 'POST',
          data,
        }),
        invalidatesTags: (result) =>
          result
            ? [
                {
                  type: 'Offering' as const,
                  id: result.investmentOfferingId,
                },
              ]
            : [],
      },
    ),

    updateInvestmentOffering: builder.mutation<
      InvestmentOffering,
      Partial<InvestmentOfferingForAPI>
    >({
      query: (data) => ({
        url: `/investment-offerings/${data.investmentOfferingId}`,
        method: 'PUT',
        data,
      }),
      async onQueryStarted({ investmentOfferingId }, { queryFulfilled, dispatch }) {
        try {
          const { data: updatedOffering } = await queryFulfilled;
          dispatch(
            investmentOfferingApi.util.updateQueryData(
              'getInvestmentOfferingById',
              Number(investmentOfferingId),
              (draft) => {
                Object.assign(draft, updatedOffering);
              },
            ),
          );
          // eslint-disable-next-line @typescript-eslint/no-explicit-any,no-empty
        } catch (err: any) {
          dispatch(
            addNotification({
              title: 'aquaty-app.notifications.error',
              message: err.error.data,
              type: 'error',
            }),
          );
        }
      },
    }),

    deleteInvestmentOffering: builder.mutation<
      null,
      { offeringId: number | string; query: string }
    >({
      query: ({ offeringId }) => ({
        url: `/investment-offerings/${offeringId}`,
        method: 'DELETE',
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            investmentOfferingApi.util.updateQueryData(
              'getInvestmentOfferings',
              arg.query,
              (draft) => [...draft].filter((el) => el.investmentOfferingId !== arg.offeringId),
            ),
          );
        } catch (err) {
          console.log('err', err);
        }
      },
    }),

    getInvestmentOfferingParticipants: builder.query<IOParticipant[], number>({
      query: (offeringId) => ({
        url: `/investment-offerings/${offeringId}/participants`,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map((el) => ({
                type: 'Participant' as const,
                id: el.investmentOfferingParticipantId,
              })),
              { type: 'Participant', id: 'LIST' },
            ]
          : [{ type: 'Participant', id: 'LIST' }],
    }),

    saveInvestmentOfferingParticipant: builder.mutation<
      IOParticipant[],
      { data: Partial<IOParticipant>[]; offeringId: number }
    >({
      query: ({ data, offeringId }) => ({
        url: `/investment-offerings/${offeringId}/participants`,
        method: 'POST',
        data,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data: newParticipants } = await queryFulfilled;
          dispatch(
            investmentOfferingApi.util.updateQueryData(
              'getInvestmentOfferingParticipants',
              arg.offeringId,
              (draft) => [...draft, ...newParticipants],
            ),
          );
        } catch (err) {
          console.log('err', err);
        }
      },
      invalidatesTags: ['Investor'],
    }),

    updateInvestmentOfferingParticipant: builder.mutation<
      IOParticipant,
      { data: Partial<IOParticipant>; offeringId: number }
    >({
      query: ({ data, offeringId }) => ({
        url: `/investment-offerings/${offeringId}/participants/${data.investmentOfferingParticipantId}`,
        method: 'PUT',
        data,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedParticipant } = await queryFulfilled;
          dispatch(
            investmentOfferingApi.util.updateQueryData(
              'getInvestmentOfferingParticipants',
              arg.offeringId,
              (draft) =>
                [...draft].map((el) =>
                  el.investmentOfferingParticipantId ===
                  updatedParticipant.investmentOfferingParticipantId
                    ? updatedParticipant
                    : el,
                ),
            ),
          );
        } catch (err) {
          console.log('err', err);
        }
      },
      invalidatesTags: ['Investor'],
    }),

    deleteInvestmentOfferingParticipant: builder.mutation<
      null,
      { offeringId: number; participantId: number }
    >({
      query: ({ offeringId, participantId }) => {
        return {
          url: `/investment-offerings/${offeringId}/participants/${participantId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: () => ['Participant', 'Investor'],
    }),

    getInvestmentOfferingInvestmentTerms: builder.query<IOInvestmentTerm[], number>({
      query: (offeringId) => ({
        url: `/investment-offerings/${offeringId}/investment-terms`,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map((el) => ({
                type: 'Investment-Term' as const,
                id: el.investmentTermsId,
              })),
              { type: 'Investment-Term', id: 'LIST' },
            ]
          : [{ type: 'Investment-Term', id: 'LIST' }],
    }),

    getInvestmentOfferingUserInvestmentTerms: builder.query<IOInvestmentTerm, number | string>({
      query: (offeringId) => ({
        url: `/investment-offerings/${offeringId}/user-investment-terms`,
      }),
      providesTags: (result) =>
        result
          ? [
              { type: 'Investment-Term' as const, id: result.investmentTermsId },
              { type: 'Investment-Term', id: 'LIST' },
            ]
          : [{ type: 'Investment-Term', id: 'LIST' }],
    }),

    saveInvestmentOfferingInvestmentTerm: builder.mutation<
      IOInvestmentTerm,
      { data: Partial<IOInvestmentTerm>; offeringId: number }
    >({
      query: ({ data, offeringId }) => ({
        url: `/investment-offerings/${offeringId}/investment-terms`,
        method: 'POST',
        data,
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const { data: newInvestmentTerm } = await queryFulfilled;
          dispatch(
            investmentOfferingApi.util.updateQueryData(
              'getInvestmentOfferingInvestmentTerms',
              arg.offeringId,
              (draft) => [...draft, newInvestmentTerm],
            ),
          );
          // eslint-disable-next-line @typescript-eslint/no-explicit-any,no-empty
        } catch (err: any) {
          dispatch(
            addNotification({
              title: 'aquaty-app.notifications.error',
              message: err.error.data,
              type: 'error',
            }),
          );
        }
      },
    }),

    updateInvestmentOfferingInvestmentTerm: builder.mutation<
      IOInvestmentTerm,
      { data: Partial<IOInvestmentTerm>; offeringId: number }
    >({
      query: ({ data, offeringId }) => ({
        url: `/investment-offerings/${offeringId}/investment-terms/${data.investmentTermsId}`,
        method: 'PUT',
        data,
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const { data: updatedInvestmentTerm } = await queryFulfilled;
          dispatch(
            investmentOfferingApi.util.updateQueryData(
              'getInvestmentOfferingInvestmentTerms',
              arg.offeringId,
              (draft) =>
                [...draft].map((el) =>
                  el.investmentTermsId === updatedInvestmentTerm.investmentTermsId
                    ? updatedInvestmentTerm
                    : el,
                ),
            ),
          );
          // eslint-disable-next-line @typescript-eslint/no-explicit-any,no-empty
        } catch (err: any) {
          console.log('err', err.message);
          dispatch(
            addNotification({
              title: 'aquaty-app.notifications.error',
              message: err.error.data,
              type: 'error',
            }),
          );
        }
      },
      invalidatesTags: ['Investor'],
    }),

    deleteInvestmentOfferingInvestmentTerm: builder.mutation<
      IOInvestmentTerm,
      { investmentTermsId: number; offeringId: number }
    >({
      query: ({ investmentTermsId, offeringId }) => ({
        url: `/investment-offerings/${offeringId}/investment-terms/${investmentTermsId}`,
        method: 'DELETE',
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            investmentOfferingApi.util.updateQueryData(
              'getInvestmentOfferingInvestmentTerms',
              arg.offeringId,
              (draft) => [...draft].filter((el) => el.investmentTermsId !== arg.investmentTermsId),
            ),
          );
        } catch (err) {
          console.log('err', err);
        }
      },
    }),

    getInvestmentOfferingAttachments: builder.query<
      IOAttachment[],
      { offeringId: number | string; query: string }
    >({
      query: ({ offeringId, query }) => ({
        url: `/investment-offerings/${offeringId}/attachments?${query}`,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map((el) => ({
                type: 'Attachment' as const,
                id: el.investmentDocumentId,
              })),
              { type: 'Attachment', id: 'LIST' },
            ]
          : [{ type: 'Attachment', id: 'LIST' }],
    }),

    saveInvestmentOfferingAttachments: builder.mutation<
      IOAttachment[],
      { data: Partial<IOAttachment>[]; offeringId: number }
    >({
      query: ({ data, offeringId }) => ({
        url: `/investment-offerings/${offeringId}/attachments`,
        method: 'POST',
        data,
      }),
      invalidatesTags: (result) =>
        result
          ? [
              ...result.map((el) => ({
                type: 'Attachment' as const,
                id: el.investmentDocumentId,
              })),
              { type: 'Attachment', id: 'LIST' },
            ]
          : [{ type: 'Attachment', id: 'LIST' }],
    }),

    updateInvestmentOfferingAttachment: builder.mutation<
      IOAttachment,
      { data: IOAttachment; offeringId: number | string }
    >({
      query: ({ data, offeringId }) => ({
        url: `/investment-offerings/${offeringId}/attachments/${data.investmentDocumentId}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: (result) =>
        result
          ? [
              {
                type: 'Attachment' as const,
                id: result.investmentDocumentId,
              },
              { type: 'Attachment', id: 'LIST' },
            ]
          : [{ type: 'Attachment', id: 'LIST' }],
    }),

    deleteInvestmentOfferingAttachment: builder.mutation<
      IOAttachment,
      { investmentDocumentId: number; offeringId: number | string }
    >({
      query: ({ investmentDocumentId, offeringId }) => ({
        url: `/investment-offerings/${offeringId}/attachments/${investmentDocumentId}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => [{ type: 'Attachment' as const }],
    }),

    getInvestmentOfferingCapitalisationDetails: builder.query<IOCapitalisationDetails, number>({
      query: (offeringId) => ({
        url: `/investment-offerings/${offeringId}/capitalisation-details`,
      }),
      providesTags: (result) =>
        result
          ? [
              {
                type: 'Capitalisation-Detail' as const,
                id: result.investmentOfferingId,
              },
            ]
          : [],
    }),

    updateInvestmentOfferingCapitalisationDetail: builder.mutation<
      IOCapitalisationDetails,
      { offeringId: number; data: Partial<IOCapitalisationDetails> }
    >({
      query: ({ offeringId, data }) => ({
        url: `/investment-offerings/${offeringId}/capitalisation-details`,
        method: 'PUT',
        data,
      }),

      async onQueryStarted({ offeringId }, { queryFulfilled, dispatch }) {
        try {
          const { data: updatedOffering } = await queryFulfilled;
          dispatch(
            investmentOfferingApi.util.updateQueryData(
              'getInvestmentOfferingCapitalisationDetails',
              offeringId,
              (draft) => {
                Object.assign(draft, updatedOffering);
              },
            ),
          );
          // eslint-disable-next-line @typescript-eslint/no-explicit-any,no-empty
        } catch (err: any) {
          dispatch(
            addNotification({
              title: 'aquaty-app.notifications.error',
              message: err.error.data,
              type: 'error',
            }),
          );
        }
      },
    }),

    getInvestmentOfferingSignings: builder.query<IOSigning[], string>({
      query: (query) => ({
        url: `/investment-offerings/signings?${query}`,
      }),
      keepUnusedDataFor: 0,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ investmentOfferingSigningId }) => ({
                type: 'Signing' as const,
                id: investmentOfferingSigningId,
              })),
              { type: 'Signing', id: 'LIST' },
            ]
          : [{ type: 'Signing', id: 'LIST' }],
    }),

    saveInvestmentOfferingSigning: builder.mutation<IOSigning, Partial<IOSigning>>({
      query: (data) => ({
        url: '/investment-offerings/signings',
        method: 'POST',
        data,
      }),
      invalidatesTags: () => [{ type: 'Signing' as const }],
    }),

    updateInvestmentOfferingSigning: builder.mutation<IOSigning, Partial<IOSigning>>({
      query: (data) => ({
        url: `/investment-offerings/signings/${data.investmentOfferingSigningId}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: () => [{ type: 'Signing' as const }],
    }),

    confirmInvestmentOfferingSining: builder.mutation<IOSigning, Partial<IOSigning>>({
      query: (data) => ({
        url: `/investment-offerings/signings/${data.investmentOfferingSigningId}/sign`,
        method: 'PUT',
      }),
      invalidatesTags: () => [{ type: 'Signing' as const }],
    }),

    confirmInvestmentOfferingPayment: builder.mutation<IOSigning, number>({
      query: (investmentOfferingSigningId) => ({
        url: `/investment-offerings/signings/${investmentOfferingSigningId}/confirm-payment`,
        method: 'PUT',
      }),
      invalidatesTags: () => [{ type: 'Signing' as const }],
    }),

    deleteInvestmentOfferingSining: builder.mutation<IOSigning, number>({
      query: (investmentOfferingSigningId) => ({
        url: `/investment-offerings/signings/${investmentOfferingSigningId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Investor', 'Signing'],
    }),

    confirmInvestmentOfferingPayments: builder.mutation({
      queryFn: (ids: number[]) => {
        const promises = ids.map((id) => {
          return http({
            url: `/investment-offerings/signings/${id}/confirm-payment`,
            method: 'PUT',
          });
        });
        return Promise.all(promises).then((results) => {
          return { data: results.map((el) => el.data).flat() };
        });
      },
      invalidatesTags: ['Investor', 'Signing'],
    }),

    getInvestmentOfferingInvestors: builder.query<IOInvestor[], number | string>({
      query: (investmentOfferingId) => ({
        url: `/investment-offerings/${investmentOfferingId}/investors`,
      }),
      transformResponse: (data: IOInvestorDTO[]) => {
        return data.map((el) => {
          if (!el.investorPhotoS3Key) {
            return { ...el, image: null };
          }
          return { ...el, image: { s3FileKey: el.investorPhotoS3Key, id: 0 } };
        });
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ investmentAccountId }) => ({
                type: 'Investor' as const,
                id: investmentAccountId,
              })),
              { type: 'Investor', id: 'LIST' },
            ]
          : [{ type: 'Investor', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetInvestmentOfferingsQuery,
  useGetInvestmentOfferingByIdQuery,
  useSaveInvestmentOfferingMutation,
  useUpdateInvestmentOfferingMutation,
  useDeleteInvestmentOfferingMutation,
  useGetInvestmentOfferingParticipantsQuery,
  useSaveInvestmentOfferingParticipantMutation,
  useUpdateInvestmentOfferingParticipantMutation,
  useDeleteInvestmentOfferingParticipantMutation,
  useGetInvestmentOfferingInvestmentTermsQuery,
  useGetInvestmentOfferingUserInvestmentTermsQuery,
  useSaveInvestmentOfferingInvestmentTermMutation,
  useUpdateInvestmentOfferingInvestmentTermMutation,
  useDeleteInvestmentOfferingInvestmentTermMutation,
  useGetInvestmentOfferingAttachmentsQuery,
  useSaveInvestmentOfferingAttachmentsMutation,
  useUpdateInvestmentOfferingAttachmentMutation,
  useDeleteInvestmentOfferingAttachmentMutation,
  useGetInvestmentOfferingCapitalisationDetailsQuery,
  useUpdateInvestmentOfferingCapitalisationDetailMutation,
  useGetInvestmentOfferingSigningsQuery,
  useSaveInvestmentOfferingSigningMutation,
  useUpdateInvestmentOfferingSigningMutation,
  useConfirmInvestmentOfferingSiningMutation,
  useConfirmInvestmentOfferingPaymentMutation,
  useDeleteInvestmentOfferingSiningMutation,
  useGetInvestmentOfferingInvestorsQuery,
  useConfirmInvestmentOfferingPaymentsMutation,
} = investmentOfferingApi;
