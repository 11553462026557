import { SVGProps, memo } from 'react';

const SvgLockCircle48Px = (props: SVGProps<SVGSVGElement>) => (
  <svg width={48} height={48} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#lock-circle-48px_svg__a)" fill="#1F2937">
      <path d="M26.917 18.167a2.916 2.916 0 1 0-5.834 0v2.5a.416.416 0 0 0 .417.416h5a.417.417 0 0 0 .417-.416v-2.5Z" />
      <path d="M24 4a20 20 0 1 0 0 40 20 20 0 0 0 0-40Zm-7.5 27.917V22.75a1.666 1.666 0 0 1 1.667-1.667h.416v-2.916a5.417 5.417 0 0 1 10.834 0v2.916h.416A1.667 1.667 0 0 1 31.5 22.75v9.167a1.667 1.667 0 0 1-1.667 1.666H18.167a1.667 1.667 0 0 1-1.667-1.666Z" />
      <path d="M24 30.25a2.083 2.083 0 1 0 0-4.167 2.083 2.083 0 0 0 0 4.167Z" />
    </g>
    <defs>
      <clipPath id="lock-circle-48px_svg__a">
        <path fill="#fff" transform="translate(4 4)" d="M0 0h40v40H0z" />
      </clipPath>
    </defs>
  </svg>
);

const Memo = memo(SvgLockCircle48Px);
export default Memo;
