/* eslint-disable indent */
import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery, publicHeaders } from 'src/utils/axios';

import { DemoUser } from 'src/interfaces/user';

export const demoApi = createApi({
  reducerPath: 'demoApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Demo-User'],
  endpoints: (builder) => ({
    getDemoUsers: builder.query<DemoUser[], string>({
      query: (query) => ({
        url: `/demo-users?${query}`,
        ...publicHeaders,
      }),
      keepUnusedDataFor: 0,
      providesTags: (result) =>
        result
          ? result.map(({ userId }) => ({
              type: 'Demo-User',
              id: userId,
            }))
          : [],
    }),

    patchDemoUser: builder.mutation<DemoUser, Partial<DemoUser>>({
      query: (data) => ({
        url: '/demo-users',
        method: 'PATCH',
        data,
      }),
    }),
  }),
});

export const { useLazyGetDemoUsersQuery, usePatchDemoUserMutation } = demoApi;
