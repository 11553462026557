// TimeClockCircle48Px

import AlertCircle48Px from 'public/svg-components/AlertCircle48Px';
import CheckCircle48Px from 'public/svg-components/CheckCircle48Px';
import TimeClockCircle48Px from 'public/svg-components/TimeClockCircle48Px';
import LockCircle48Px from 'public/svg-components/LockCircle48Px';
import BinCircle48Px from 'public/svg-components/BinCircle48Px';

import classes from './index.module.scss';
import { classNames } from 'src/utils/classNames';
import InformationCircleFill24Px from 'public/svg-components/InformationCircleFill24Px';
import { LottieAnimation } from 'src/server-shared/lottie';
import * as CheckMark from 'public/lottie/check-mark.json';

const icons = {
  waiting: <TimeClockCircle48Px />,
  success: <CheckCircle48Px />,
  alert: <AlertCircle48Px />,
  info: <InformationCircleFill24Px width={48} height={48} viewBox="0 0 24 24" />,
  lock: <LockCircle48Px />,
  archive: <BinCircle48Px />,
};

type IconType = 'waiting' | 'alert' | 'success' | 'info' | 'success-animation' | 'lock' | 'archive';
type IconSize = 'l' | 'm';

interface BigCircleWithIconProps {
  type: IconType;
  size?: IconSize;
}
export const BigCircleWithIcon = ({ type, size = 'l' }: BigCircleWithIconProps) => {
  if (type === 'success-animation') {
    return <LottieAnimation width={120} height={120} animationData={CheckMark} loop={false} />;
  }

  return (
    <div
      className={classNames(classes['icon-container'], 'flex', {
        [classes[`icon-container--${type}`]]: true,
        [classes[size]]: true,
      })}
    >
      {icons[type]}
    </div>
  );
};
