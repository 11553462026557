import { FC, useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import Head from 'next/head';
import { getCookie } from 'cookies-next';
import { useSelector } from 'react-redux';

import { HOME_PAGE, NOT_AUTHORIZED_PAGES, REGISTRATION_PAGE } from 'src/constants/paths';
import { COGNITO_JWT } from 'src/constants/loggedIn';
import { ApplicationSidebar } from './application-sidebar';
import { ApplicationHeader } from 'src/server-shared/header/application-header';
import { checkFinishedRegister } from './checkFinishedRegister';
import { ServerSharedLoader } from 'src/server-shared/loader';
import { PDFViewerProvider } from 'src/shared/PDfViewerV2/provider';
import classes from './index.module.scss';
import { classNames } from 'src/utils/classNames';
import { getStaffImpersonate } from 'src/store/staff-impersonate/reducer';
import { useGetUserQuery } from 'src/store/user/api';
import { USER_ID } from 'src/constants/registration';

export const AppLayout: FC = ({ children }) => {
  const loggedUserId = getCookie(USER_ID);
  const { pathname, query, push } = useRouter();

  const { userId, adminView } = useSelector(getStaffImpersonate);

  const { data: user, isLoading } = useGetUserQuery(null, { skip: !loggedUserId });
  const isLoggedIn = getCookie(COGNITO_JWT);

  const hideSidebar = useMemo(() => !!userId && !!adminView, [adminView, userId]);

  const isPublicPage = useMemo(() => NOT_AUTHORIZED_PAGES.includes(pathname), [pathname]);

  useEffect(() => {
    if (!isLoading && isLoggedIn) {
      const instructions = checkFinishedRegister(pathname, user);
      if (typeof instructions === 'string') {
        push({ pathname: instructions, query });
        return;
      }
      if (
        typeof instructions === 'object' &&
        instructions !== null &&
        instructions.skipAllRedirects
      ) {
        return;
      }

      if (pathname.includes('auth') || pathname === '/') {
        push(HOME_PAGE);
      }
    }
    if (isLoggedIn && !loggedUserId && ['/home'].includes(pathname)) {
      push({ pathname: REGISTRATION_PAGE, query });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, pathname, query]);

  if (isLoading) {
    return <ServerSharedLoader />;
  }

  return isPublicPage ? (
    <>
      <Head>
        <title>AQUATY</title>
      </Head>
      <div className={classes.public}>{children}</div>
    </>
  ) : (
    <div className={classNames(classes.app, { [classes['hide-sidebar']]: hideSidebar })}>
      {!hideSidebar && <ApplicationSidebar />}
      <ApplicationHeader hideSidebar={hideSidebar} />
      <PDFViewerProvider>
        <main className={classes.app__main}>{children}</main>
      </PDFViewerProvider>
    </div>
  );
};
