import { useMemo, useCallback } from 'react';
import { useRouter } from 'next/navigation';
import { useTranslation } from 'next-i18next';
import { components, MenuListProps } from 'react-select';
import { ServerSharedSimpleProgress } from 'src/server-shared/funding-stats/progress-bar';
import { StepIndicatorObject } from 'src/server-shared/page-step-indicator';
import { Option, OptionWithImageExpend } from '../types';

import Add16Px from 'public/svg-components/Add16Px';
import { ServerSharedText } from 'src/server-shared/texts/text';
import { classNames } from 'src/utils/classNames';
import classes from './index.module.scss';
import { User } from 'src/interfaces/user';
import Logout24Px from 'public/svg-components/Logout24Px';
import { ServerSharedAQImageV2 } from 'src/server-shared/aq-image';
import { UserPlaceholderIcon } from 'src/server-shared/texts/user-placeholder-icon';
import UserIcon from 'public/svg-components/UserIcon';
import { REGISTRATION_PAGE } from 'src/constants/paths';
import { useLogout, useSoftRegisteredUserLogout } from 'src/hooks/useLogout';
import { emailRegEx } from 'src/utils/validation';
import { OfflineInvestmentDocumentMenuProps } from '../document-menu';
import { ServerSharedButton } from 'src/server-shared/buttons/button';
import { contactSupportHandler } from 'src/ui/profile-completion/shared';

interface MenuListComponentHeaderStepperProps extends MenuListProps<StepIndicatorObject> {
  pageName: string;
  button: React.ReactNode | null;
}

export const MenuListComponentHeaderStepper = (props: MenuListComponentHeaderStepperProps) => {
  const { t } = useTranslation();
  const { button, options } = props;

  const completedSteps = useMemo(() => {
    return options.filter((el) => {
      if ('complete' in el) {
        return el.complete;
      }
      return false;
    });
  }, [options]);

  return (
    <components.MenuList {...props}>
      <div className={classNames('grid-row m', classes['header-stepper-menu-list--header'])}>
        <ServerSharedText type="label-l" color="body">
          {t(props.pageName)}
        </ServerSharedText>

        <ServerSharedSimpleProgress
          current={completedSteps.length}
          expected={props.options.length}
        />
      </div>
      {props.children}
      {button}
    </components.MenuList>
  );
};

interface HeaderMenuListProps extends MenuListProps {
  openModal: () => void;
}

export const HeaderMenuList = (props: HeaderMenuListProps) => {
  const { t } = useTranslation();
  return (
    <components.MenuList {...props}>
      {props.children}
      <div className={classes['menu-list']} />
      <div
        className={classNames(
          'grid-column template-mc-1fr align-items-center',
          classes['menu-list__footer'],
        )}
        onClick={props.openModal}
      >
        <div className={classNames('flex', classes['menu-list__circle'])}>
          <Add16Px />
        </div>
        <ServerSharedText color="body">{t('aquaty-generic.cta.register-venture')}</ServerSharedText>
      </div>
    </components.MenuList>
  );
};

export const InviteUserMenu = (props: MenuListProps<Option>) => {
  const { t } = useTranslation();
  return (
    <components.MenuList {...props}>
      {props.options.length > 0 && (
        <div className={classes['invite-menu-list']}>
          <ServerSharedText type="text-small" color="grey500">
            {t('aquaty-generic.cta.select-a-person')}
          </ServerSharedText>
        </div>
      )}
      {props.children}
    </components.MenuList>
  );
};

export const InvitePartnersMenu = (props: MenuListProps<OptionWithImageExpend>) => {
  const { t } = useTranslation();
  const isEmailValue = useMemo(() => {
    if (props.options.length === 1) {
      const { value } = props.options[0] as OptionWithImageExpend;
      const validEmail = emailRegEx.test(value);
      return validEmail;
    }
    return false;
  }, [props.options]);

  return (
    <components.MenuList {...props}>
      <div className={classes['invite-menu-list']}>
        <ServerSharedText type="text-small" color="grey500">
          {t(
            isEmailValue
              ? 'aquaty-generic.cta.keep-typing-to-invite-email'
              : 'aquaty-generic.cta.select-a-person',
          )}
        </ServerSharedText>
      </div>
      {props.children}
    </components.MenuList>
  );
};

interface SoftRegisteredUserMenuListProps extends MenuListProps {
  user: User;
}

export const SoftRegisteredUserMenuList = (props: SoftRegisteredUserMenuListProps) => {
  const { t } = useTranslation();
  const { user } = props;
  const { push } = useRouter();
  const { logout } = useSoftRegisteredUserLogout();

  const completeRegistration = useCallback(() => {
    push(REGISTRATION_PAGE);
  }, [push]);

  return (
    <components.MenuList {...props}>
      <div
        className={classNames(
          'grid-column template-mc-1fr align-items-center',
          classes['soft-registered-user-menu'],
        )}
      >
        <ServerSharedAQImageV2 width={24} height={24} borderRadius="xxl" image={user.photo}>
          {!user.photo && <UserPlaceholderIcon fullName={user.fullName} />}
        </ServerSharedAQImageV2>
        <ServerSharedText color="body">{user.email}</ServerSharedText>
      </div>

      <div
        className={classNames(
          'grid-column template-mc-1fr align-items-center',
          classes['soft-registered-user-menu'],
          classes['pointer'],
        )}
        onClick={completeRegistration}
      >
        <UserIcon className={classes['menu-icons']} />
        <ServerSharedText color="body">
          {t('aquaty-generic.cta.complete-registration')}
        </ServerSharedText>
      </div>

      <div className={classes['menu-list']} />
      <div
        className={classNames(
          'grid-column template-mc-1fr align-items-center',
          classes['menu-list__footer'],
        )}
        onClick={logout}
      >
        <div className="flex">
          <Logout24Px className={classes['menu-icons']} />
        </div>
        <ServerSharedText color="body">{t('aquaty-generic.cta.logout')}</ServerSharedText>
      </div>
    </components.MenuList>
  );
};

export const ProfileCompletionUserMenuList = (props: SoftRegisteredUserMenuListProps) => {
  const { t } = useTranslation();
  const { user } = props;
  const { logout } = useLogout();

  return (
    <components.MenuList {...props}>
      <div
        className={classNames(
          'grid-column template-mc-1fr align-items-center',
          classes['soft-registered-user-menu'],
        )}
      >
        <ServerSharedAQImageV2 width={24} height={24} borderRadius="xxl" image={user.photo}>
          {!user.photo && <UserPlaceholderIcon fullName={user.fullName} />}
        </ServerSharedAQImageV2>
        <ServerSharedText color="body">{user.email}</ServerSharedText>
      </div>
      {/* TODO add translation */}

      <div className={classes['menu-list']} />
      <div
        className={classNames(
          'grid-column template-mc-1fr align-items-center',
          classes['menu-list__footer'],
        )}
        onClick={logout}
      >
        <div className="flex">
          <Logout24Px className={classes['menu-icons']} />
        </div>
        <ServerSharedText color="body">{t('aquaty-generic.cta.logout')}</ServerSharedText>
      </div>
    </components.MenuList>
  );
};

interface OfflineInvestmentDocumentMenuMenuListProps
  extends MenuListProps,
    OfflineInvestmentDocumentMenuProps {
  onBlur: () => void;
}

export const OfflineInvestmentDocumentMenuMenuList = (
  props: OfflineInvestmentDocumentMenuMenuListProps,
) => {
  const { t } = useTranslation();
  return (
    <components.MenuList {...props}>
      <div
        className="grid-row none"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <div className={classes['offline-investment-document-menu']} onClick={props.openHandler}>
          <ServerSharedText type="label" color="primary600">
            {t('aquaty-generic.cta.open')}
          </ServerSharedText>
        </div>
        <div
          className={classes['offline-investment-document-menu']}
          onClick={() => {
            if (props.renameHandler) {
              props.renameHandler();
              props.onBlur();
            }
          }}
        >
          <ServerSharedText type="label" color="primary600">
            {t('aquaty-generic.cta.rename')}
          </ServerSharedText>
        </div>
        <div
          className={classes['offline-investment-document-menu']}
          onClick={() => {
            if (props.deleteHandler) {
              props.deleteHandler();
              props.onBlur();
            }
          }}
        >
          <ServerSharedText type="label" color="alert600">
            {t('aquaty-generic.cta.delete-btn')}
          </ServerSharedText>
        </div>
      </div>
    </components.MenuList>
  );
};

interface VentureSelectsMenuPro extends MenuListProps {
  addCompanyHandler?: (v: { name: string; registrationCountry: string }) => void;
  registrationCountry: string;
}

export const VentureSelectsMenu = (props: VentureSelectsMenuPro) => {
  const { t } = useTranslation();
  const isManually = useMemo(() => {
    if (props.options.length === 1) {
      const { value } = props.options[0] as OptionWithImageExpend;
      return value === 'manually';
    }
    return false;
  }, [props.options]);

  const companyName = useMemo(() => {
    if (props.options.length === 1) {
      const { value, label } = props.options[0] as OptionWithImageExpend;
      return value === 'manually' ? label : '';
    }
    return '';
  }, [props.options]);

  return (
    <components.MenuList {...props}>
      {props.options.length > 0 && (
        <>
          <div className={classes['invite-menu-list']}>
            <ServerSharedText type={isManually ? 'text-italic' : 'text-small'} color="grey500">
              {t(
                isManually
                  ? 'aquaty-generic.select-venture-dropdown.no-results'
                  : 'aquaty-generic.select-venture-dropdown.select-venture',
              )}
            </ServerSharedText>
          </div>
          {isManually && props.addCompanyHandler && (
            <div className={classes['invite-menu-list']}>
              <ServerSharedButton
                variant="text"
                label={t('aquaty-generic.select-venture-dropdown.add-company', { companyName })}
                onClick={() => {
                  if (props.addCompanyHandler) {
                    props.addCompanyHandler({
                      name: companyName,
                      registrationCountry: props.registrationCountry,
                    });
                  }
                }}
              />
            </div>
          )}

          {isManually && !props.addCompanyHandler && (
            <div className={classes['invite-menu-list']}>
              <ServerSharedButton
                variant="text"
                label={t('aquaty-generic.menu.contact-support.title')}
                onClick={contactSupportHandler}
              />
            </div>
          )}
        </>
      )}
      {props.children}
    </components.MenuList>
  );
};

export const AddHoldingMenu = (props: MenuListProps<Option>) => {
  const { t } = useTranslation();
  return (
    <components.MenuList {...props}>
      <div className={classes['invite-menu-list']}>
        <ServerSharedText type="text-italic" color="grey500">
          {t('aquaty-generic.add-holding-dropdown.no-investment-accounts')}
        </ServerSharedText>
      </div>
      {props.children}
    </components.MenuList>
  );
};

export const RegisterClubSPVgMenu = (props: MenuListProps<Option>) => {
  const { t } = useTranslation();
  return (
    <components.MenuList {...props}>
      <div className={classes['invite-menu-list']}>
        <ServerSharedText type="text-italic" color="grey500">
          {t('aquaty-generic.register-club-spv-dropdown.no-club-spv')}
        </ServerSharedText>
      </div>
      {props.children}
    </components.MenuList>
  );
};

export const InviteParticipantsMenu = (props: MenuListProps<Option | any>) => {
  const { t } = useTranslation();
  const showText = props.options.every((el) => {
    return 'options' in el ? false : true;
  });
  const showInviteByEmailText =
    props.options.length === 1 && emailRegEx.test(props.options[0].label);
  return (
    <components.MenuList {...props}>
      {props.options.length > 0 && showText && (
        <div className={classes['invite-menu-list']}>
          <ServerSharedText type="text-small" color="grey500">
            {t(
              showInviteByEmailText
                ? 'aquaty-generic.cta.keep-typing-to-invite-email'
                : 'aquaty-generic.cta.select-a-person',
            )}
          </ServerSharedText>
        </div>
      )}
      {props.children}
    </components.MenuList>
  );
};
