import { memo } from 'react';
import Lottie from 'react-lottie';
// import * as animationData from 'public/lottie/ai-spinner.json';

interface LottieAnimationProps {
  height?: number;
  width?: number;
  animationData: any;
  loop?: boolean;
}

export const LottieAnimation = memo(
  ({ height = 24, width = 24, animationData, loop = true }: LottieAnimationProps) => {
    const defaultOptions = {
      loop: loop,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };

    return <Lottie options={defaultOptions} height={height} width={width} />;
  },
);
