import { removeNestedNullUndefined } from './objects';

export const generateQueryString = <T>(params: any) => {
  return Object.keys(removeNestedNullUndefined(params))
    .map((key: any) => {
      if (Array.isArray(params[key as keyof T])) {
        return params[key as keyof T]
          .map((item: number) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(item);
          })
          .join('&');
      } else {
        return encodeURIComponent(key) + '=' + encodeURIComponent(params[key as keyof T]);
      }
    })
    .join('&');
};
