import { useTranslation } from 'react-i18next';
import { ServerSharedText } from 'src/server-shared/texts/text';
import { GeneralModalProps, ServerSharedModal } from '../modal';
import classes from './index.module.scss';
import { classNames } from 'src/utils/classNames';
import { BigCircleWithIcon } from 'src/server-shared/icons/big-circle-with-icon';

export const MobileSupportModal = ({ show, closeEvent }: GeneralModalProps) => {
  const { t } = useTranslation();
  return (
    <ServerSharedModal
      show={show}
      closeEvent={closeEvent}
      size="full"
      footer={false}
      header={{ withBorder: false }}
      bodyInlineStyles={{ height: '100%' }}
    >
      <div className={classNames('flex column xl', classes['mobile-support-wrapper'])}>
        <BigCircleWithIcon type="info" />
        <div className={'grid-row xs'}>
          <ServerSharedText type="label-l" className="text-align-center">
            {t('aquaty-app.modal.mobile-support.title')}
          </ServerSharedText>
          <ServerSharedText as="pre" color="grey500" className="text-align-center">
            {t('aquaty-app.modal.mobile-support.subtitle')}
          </ServerSharedText>
        </div>
      </div>
    </ServerSharedModal>
  );
};
